import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import seedrandom from 'seedrandom';

const { facadeService, exportService } = window.config();
console.log(`facade: ${facadeService}`)
console.log(`export: ${exportService}`)

const CAT_AUTHORING = 'authoring',
      CAT_HARVESTING = 'harvesting',
      CAT_COMPLETED = 'completed',
      validCategories = [CAT_AUTHORING, CAT_HARVESTING, CAT_COMPLETED];

const Rule = ({name, value, updateParent, setName, setVal}) => {
  const [cursor, setCursor] = useState(null);
  const ruleRef = React.useRef();

  let newVal = value.split("|");

  const nameChange = e => {
    setName(e.target.value);
  };

  const handleText = i => e => {
    let oldCount = newVal.length;
    newVal[i] = e.target.value;
    setVal(newVal.join("|"));
    if (oldCount !== newVal.join("|").split("|").length) {
      setCursor([i+1,0]);
    }
  };

  useEffect(() => {
    if (cursor === null) return;

    const [childidx, textidx] = cursor;
    let target = [].slice.call(ruleRef.current.children).filter(e => e.tagName === "TEXTAREA")[childidx];
    target.focus();
    target.setSelectionRange(textidx, textidx);
  }, [cursor]);

  const onKeyDown = i => e => {
    let cursorStart = e.target.selectionStart === 0;
    let cursorEnd = e.target.selectionStart === (newVal[i].length);

    if (e.key === 'Enter') {
      newVal[i] = newVal[i].substr(0, e.target.selectionStart) + "|" + newVal[i].substr(e.target.selectionEnd);
      setVal(newVal.join("|"));
      setCursor([i+1, 0]);
      e.preventDefault();
      return true;
    }

    if (e.target.selectionStart !== e.target.selectionEnd) {
      return;
    }

    if (i !== 0 && e.key === 'ArrowLeft' && cursorStart) {
      setCursor([i-1, newVal[i-1].length]);
      return true;
    }

    if (i !== 0 && e.key === 'ArrowUp' && cursorStart) {
      setCursor([i-1, 0]);
      return true;
    }


    if (i < newVal.length - 1 && e.key === 'ArrowRight' && cursorEnd) {
      setCursor([i+1, 0]);
      return true;
    }

    if (i < newVal.length - 1 && e.key === 'ArrowDown' && cursorEnd) {
      setCursor([i+1, newVal[i+1].length]);
      return true;
    }

    if (i !== 0 && e.key === 'Backspace' && cursorStart) {
      let cursorPos = newVal[i-1].length;
      newVal[i-1] = newVal[i-1] + newVal[i];
      newVal.splice(i,1);
      setVal(newVal.join("|"));
      setCursor([i-1, cursorPos]);
      e.preventDefault();
      return true;
    }

    if (i < newVal.length - 1 && e.key === 'Delete' && cursorEnd) {
      let cursorPos = newVal[i].length;
      newVal[i] = newVal[i] + newVal[i+1];
      newVal.splice(i+1,1);
      setVal(newVal.join("|"));
      setCursor([i, cursorPos]);
      e.preventDefault();
      return true;
    }
  };

  // change this to an array of refs
  return <><div key="-1" className="ruleName"><input onChange={nameChange} value={name} symbol={name} idx={-1} placeholder="Rule Symbol" /></div> → <div className="ruleValue" ref={ruleRef}>
    {newVal.map((e, i) => [
      <div className="ruleLabel">{(newVal.length > 1 ? ("" + (i+1) + "\u00A0") : "")}</div>,
      <TextareaAutosize key={"rule" + name.toString() + "." + i.toString()} onChange={handleText(i)} onKeyDown={onKeyDown(i)} value={e} placeholder="empty" symbol={name} idx={i} wrap="soft" />,
      (i + 1 < newVal.length ? " | " : "")
    ])}
  </div></>;
}

class GrammarEditor extends React.Component {
  saveTimer = null;
  worker = null;

  constructor(props) {
    super(props);
    this.state = {
      allRules: undefined,
      title: 'untitled',
      rngSeed: 'hello.',
      generateAll: false,
      generateFull: false,
      loading: false,
      showHelp: false,
      selected: [],
      optionCount: 3,
      workerOutput: null,
      workerUpdating: false,
      category: null,
      lastUpdate: null,
      lastDownload: null,
    };
  }

  loadProblem() {
    const defaultGrammar = "[[\"S\", \"\"], [\"CO\", \"\"], [\"DO\", \"\"]]";
    if (this.state.allRules === undefined && !this.state.loading) {
        this.setState({loading: true});
          fetch(`//${facadeService}/problems/`+this.props.ruleIndex)
              .then(res => {
                if (res.status !== 200) {
                  return {grammar: defaultGrammar}; // default grammar, horrible, horrible
                }
                return res.json();
              })
              .then(json => {
                let grammar = json.grammar || defaultGrammar;
                let newRules = JSON.parse(grammar);
                this.setState({
                  allRules: newRules,
                  loading: false,
                  title: json.title,
                  hesiname: json.hesiname,
                  category: json.category,
                  lastUpdate: !!json.lastUpdate ? new Date(json.lastUpdate).toDateString() : null,
                  vid: json.id || 0,
                  lastDownload: !!json.lastDownload ? new Date(json.lastDownload).toDateString() : null,
                  selected: JSON.parse(json.selection_json || '[]'),
                });
                this.runWorker(newRules, this.state.selected, this.state.optionCount);
              })
              .catch(console.error);
    }
  }

  componentDidMount() {
    this.loadProblem();
  }

  componentWillUnmount() {
    if (this.saveTimer !== null) {
      clearTimeout(this.saveTimer);
    }
  }

  addRule() {
    let newRules = this.state.allRules;
    newRules.push([String.fromCharCode(65 + newRules.length), ""]);
    this.grammarUpdate(newRules);
  }

  deleteRule(i) {
    let newRules = this.state.allRules.slice();
    if (i > 0) {
      newRules.splice(i,1);
    }
    this.grammarUpdate(newRules);
  }

  parseOption(rule) {
    let lidx = rule.indexOf("<");
    let ridx = rule.indexOf(">", lidx);
    if (lidx < 0 || ridx < 0) {
      return [rule];
    }
    return [rule.slice(0, lidx), [rule.slice(lidx+1, ridx)]].concat(this.parseOption(rule.slice(ridx+1)));
  }

  parseRule(ruleVal) {
    return ruleVal.split("|").map((e) => this.parseOption(e));
  }

  parseRulesParam(allRules) {
    return allRules.map((e) => [e[0], this.parseRule(e[1])]);
  }

  parseGrammar() {
    return this.parseRulesParam(this.state.allRules);
  }

  rerandom() {
    this.setState({
      rngSeed: seedrandom(this.state.rngSeed)()
    });
  }

  toggleAll() {
    this.setState({
      generateAll: !this.state.generateAll,
      generateFull: false
    });
  }

  toggleFull() {
    this.setState({
      generateFull: !this.state.generateFull
    });
  }

  save() {
    fetch(`//${facadeService}/problems/`+this.props.ruleIndex, {
      method: "POST",
      body: JSON.stringify(this.state.allRules)
    })
      .then(res => {
        if (res.status !== 200) {
          console.log(`bad status: ${res.status}`);
        }
        return res.json();
      })
      .then(jres => {
        this.setState({vid: jres});
      })
      .catch(console.error);
  }

  debouncedSave() {
    if (this.saveTimer !== null) {
      clearTimeout(this.saveTimer);
    }

    this.saveTimer = setTimeout(this.save.bind(this), 1000);
  }

  saveSelection(selection) {
    fetch(`//${facadeService}/selection/`+this.props.ruleIndex, {
      method: "POST",
      body: JSON.stringify(selection)
    })
      .then(res => {
        if (res.status !== 200) {
          console.log(`bad status: ${res.status}`);
        }
      })
      .catch(console.error);
  }

  sample(grammar, symbol, rng) {
    let rvalue = {tokens: [], rules: []};
    let ruleSet = grammar.filter(e => e[0] === symbol)[0][1];

    /* failure */
    /* TODO throw an error here */
    if (ruleSet === null) {
      return rvalue;
    }

    let ruleSetIdx = Math.floor(rng() * ruleSet.length);
    let rule = ruleSet[ruleSetIdx];

    rule.map((e, i) => {
      if (typeof(e) === "string") {
        rvalue.tokens.push(e);
      } else {
        let child = this.sample(grammar, e[0], rng);
        rvalue.tokens = rvalue.tokens.concat(child.tokens);
        rvalue.rules = rvalue.rules.concat(child.rules);
      }
      return -1; // TODO
    });

    rvalue.rules.push([symbol, ruleSetIdx]);

    return rvalue;
  }

  highlight(rules) {
    if (rules) {
      rules.map(rule => Array.from(document.getElementsByTagName("textarea")).filter(e => e.getAttribute("symbol") === rule[0] && e.getAttribute("idx") === rule[1].toString()).map(e => e.className = "highlight"));
    }
  }

  dehighlight(rules) {
    if (rules) {
      rules.map(rule => Array.from(document.getElementsByTagName("textarea")).filter(e => e.getAttribute("symbol") === rule[0] && e.getAttribute("idx") === rule[1].toString()).map(e => e.className = ""));
    }
  }

  generateAll(grammar, symbol) {
    let rvalue = [];
    let ruleSet = null;
    grammar.map((e, i) => {
      if (e[0] === symbol) {
        ruleSet = e[1];
      }
      return -1; // TODO
    });

    /* failure */
    /* TODO throw an error here */
    if (ruleSet === null) {
      return rvalue;
    }

    ruleSet.map((rule, j) => {
      let myValue = [{tokens: [], rules: [[symbol, j]]}];
      rule.map((e, i) => {
        if (typeof(e) === "string") {
          myValue = myValue.map((ee, ii) => ({
                  tokens: ee.tokens.concat([e]),
                  rules: ee.rules
          }));
        } else {
          let subvalue = this.generateAll(grammar, e[0]);
          myValue =
          subvalue.map((ee, ii) => (
                    myValue.map(
                            (eee, iii) => ({tokens: eee.tokens.concat(ee.tokens), rules: eee.rules.concat(ee.rules)})
                    )
          )).reduce((a1, a2) => (a1.concat(a2)), []);
        }
        return -1; // TODO
      });
      rvalue = rvalue.concat(myValue);
      return -1;
    });

    return rvalue;
  }

  count(grammar, symbol) {
    let rvalue = 0;
    let ruleSet = null;
    grammar.map((e, i) => {
      if (e[0] === symbol) {
        ruleSet = e[1];
      }
      return -1; // TODO
    });

    /* failure */
    /* TODO throw an error here */
    if (ruleSet === null) {
      return rvalue;
    }

    ruleSet.map((rule, j) => {
      let myCount = 1;
      rule.map((e, i) => {
        if (typeof(e) !== "string") {
          myCount *= this.count(grammar, e[0]);
        }
        return -1; // TODO
      });
      rvalue += myCount;
      return -1;
    });

    return rvalue;
  }

  setName(i) {
    return (newName) => {
      let newRules = this.state.allRules.slice();
      newRules[i][0] = newName;
      this.grammarUpdate(newRules);
    };
  }

  setVal(i) {
    return (newVal) => {
      let newRules = this.state.allRules.slice();
      newRules[i][1] = newVal;
      this.grammarUpdate(newRules);
    };
  }

  clearSelected() {
    this.setState({selected: []});
    this.saveSelection([]);
    this.runWorker(this.state.allRules, [], this.state.optionCount);
  }

  toggleSelected(pidx, cidx, didxes) {
    let idx = this.findSelected(pidx, cidx, didxes);
    let oldSelected = this.state.selected.slice();
    if (idx < 0) {
      oldSelected.push({stem: pidx, correct: cidx, distract: didxes});
    } else {
      oldSelected.splice(idx, 1);
    }
    this.setState({selected: oldSelected});
    this.saveSelection(oldSelected);
    this.runWorker(this.state.allRules, oldSelected, this.state.optionCount);
  }

  findSelected(pidx, cidx, didxes) {
    for (let idx = 0; idx < this.state.selected.length; idx++) {
      let selected = this.state.selected[idx];
      if (selected.stem === pidx && selected.correct === cidx && 
        selected.distract.length === didxes.length && selected.distract.every((value, index) => value === didxes[index])) {
        return idx;
      }
    }
    return -1;
  }


  answerTag (sampleOut, idx) {
    sampleOut.index = idx;

    let lemma = sampleOut.tokens.join("");
    let tagList = [];
    while (lemma.indexOf("#") >= 0 && lemma.indexOf("#", lemma.indexOf("#")+1) >= 0) {
      let idx1 = lemma.indexOf("#");
      let idx2 = lemma.indexOf("#", idx1+1);
      tagList.push(lemma.slice(idx1+1, idx2));
      lemma = lemma.slice(0, idx1) + lemma.slice(idx2+1);
    }

    tagList.sort();

    sampleOut.tags = tagList.join("#");

    tagList = [];
    while (lemma.indexOf("@") >= 0 && lemma.indexOf("@", lemma.indexOf("@")+1) >= 0) {
      let idx1 = lemma.indexOf("@");
      let idx2 = lemma.indexOf("@", idx1+1);
      tagList.push(lemma.slice(idx1+1, idx2));
      lemma = lemma.slice(0, idx1) + lemma.slice(idx2+1);
    }

    tagList.sort();
    sampleOut.oneOf = tagList;

    tagList = [];
    while (lemma.indexOf("{") >= 0 && lemma.indexOf("}", lemma.indexOf("{")+1) >= 0) {
      let idx1 = lemma.indexOf("{");
      let idx2 = lemma.indexOf("}", idx1+1);
      tagList.push(lemma.slice(idx1+1, idx2));
      lemma = lemma.slice(0, idx1) + lemma.slice(idx2+1);
    }

    sampleOut.rationale = tagList.join("");
    sampleOut.lemma = lemma;

    return sampleOut;
  }

  delta (s1, s2) {
    let str1 = s1.split(" ");
    let str2 = s2.split(" ");
    const track = Array(str2.length + 1).fill(null).map(() =>
      Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
      track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
      track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
      for (let i = 1; i <= str1.length; i += 1) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1, // deletion
          track[j - 1][i] + 1, // insertion
          track[j - 1][i - 1] + indicator, // substitution
        );
      }
    }
    return track[str2.length][str1.length];
  }

  symbolsUsed(grammar) {
    return grammar.map(defn => defn[1].map((rule, idx) => rule.filter(x => typeof(x) !== "string").map(x => ({token: x[0], usedby: defn[0], index: idx}))).reduce((v1, v2) => (v1.concat(v2)), [])).reduce((v1, v2) => (v1.concat(v2)), []);
  }

  symbolsDefined(grammar) {
    return grammar.map(defn => defn[0]);
  }

  // basically do DFS
  hasCycles(grammar, symbol, chain) {
    if (chain === undefined) {
      chain = [];
    }

    if (chain.indexOf(symbol) >= 0) {
      return true;
    }

    let ruleSet = grammar
      .filter(e => e[0] === symbol)
      .map(e => e[1])
      .reduce((x, y) => x.concat(y), []);

    for (let rule of ruleSet) {
      for (let token of rule) {
        if (typeof(token) === "string") continue;
        let child_symbol = token[0];
        if (this.hasCycles(grammar, child_symbol, chain.concat([symbol]))) {
          return true;
        }
      }
    }
    return false;
  }

  allMatchingSubsets(correctOptions, distractors, exclude) {
    // select one correct option
    // select three distractors
    let rval = [];
    for (let i=0; i<correctOptions.length; i++) {
      if (correctOptions[i].oneOf.filter(val => exclude.includes(val)).length > 0) continue;
      for (let j0=0; j0<distractors.length; j0++) {
        if (distractors[j0].oneOf.filter(val => exclude.includes(val)).length > 0) continue;
        if (distractors[j0].oneOf.filter(val => correctOptions[i].oneOf.includes(val)).length > 0) continue;
        for (let j1=j0+1; j1<distractors.length; j1++) {
          if (distractors[j1].oneOf.filter(val => exclude.includes(val)).length > 0) continue;
          if (distractors[j1].oneOf.filter(val => correctOptions[i].oneOf.includes(val)).length > 0) continue;
          if (distractors[j1].oneOf.filter(val => distractors[j0].oneOf.includes(val)).length > 0) continue;
          for (let j2=j1+1; j2<distractors.length; j2++) {
            if (distractors[j2].oneOf.filter(val => exclude.includes(val)).length > 0) continue;
            if (distractors[j2].oneOf.filter(val => correctOptions[i].oneOf.includes(val)).length > 0) continue;
            if (distractors[j2].oneOf.filter(val => distractors[j0].oneOf.includes(val)).length > 0) continue;
            if (distractors[j2].oneOf.filter(val => distractors[j1].oneOf.includes(val)).length > 0) continue;
            rval.push({correct: correctOptions[i], distractors: [distractors[j0], distractors[j1], distractors[j2]]});
          }
        }
      }
    }
    return rval;
  }

  download(fmt, selectedOnly) {
    // TODO use the worker-generated data

    let data = [["Item_Type", "Title", "Hesiname", "Description", "Stem", "Rationale", "Choice_1_Val", "Choice_2_Val", "Choice_3_Val", "Choice_4_Val", "Correct_Answer", "Selected"]];
    let allOutputs = this.generateAll(this.parseGrammar(), "S").map((e, i) => this.answerTag(e, i));
    let allCorrect = this.generateAll(this.parseGrammar(), "CO").map((e, i) => this.answerTag(e, i)) || [];
    let allDistractors = this.generateAll(this.parseGrammar(), "DO").map((e, i) => this.answerTag(e, i)) || [];
    for (let output of allOutputs) {
      let correctTmp = allCorrect.filter(at => at.tags === output.tags);
      let distractTmp = allDistractors.filter(at => at.tags === output.tags);
      let allMatchingSubsets = this.allMatchingSubsets(correctTmp, distractTmp, output.oneOf);
      for (let match of allMatchingSubsets) {
        let row = ["MCSA", this.state.title, this.state.hesiname + "_" + this.state.vid, ""];
        row.push(output.lemma);
        row.push(match.correct.rationale);
        row.push(match.correct.lemma);
        for (let cell of match.distractors) {
          row.push(cell.lemma);
        }
        row.push("1");
        if (this.findSelected(output.index, match.correct.index, match.distractors.map(dis => dis.index)) >= 0) {
          row.push("1");
        } else {
          row.push("");
        }
        data.push(row);
      }
    }
    let idxlen = ("" + data.length).length;
    for (let idx = 0; idx < data.length; idx++) {
      data[idx][2] = data[idx][2] + ("" + idx).padStart(idxlen, '0');
    }

    if (selectedOnly) {
      data = data.filter(row => row[11].length > 0);
    }

    if (fmt === "CSV" || fmt === 'XLS' || fmt === 'DOCX') {
      let file = new Blob(data.map((row) => row.map(cell => "\"" + cell.replace("\"", "\"\"") + "\"").join(",") + "\n"), {type: "text/csv"});
      if (fmt ==='CSV') {
        this.displayDownloadDialog(file, fmt);
        return;
      }

      fetch(`//${exportService}/${fmt.toLowerCase()}`, {body: file, method: 'POST'})
        .then(res => {
          if (res.ok) {
            return res.blob();
          }

          res.text().then(err => {
            console.error(err);
            alert(`Ben's script returned with the message: ${err}\n\nPlease try again.`);
          });
        })
        .then(blob => this.displayDownloadDialog(blob, fmt))
        .catch(console.error);
    } else {
      // TODO this is fragile
      this.displayDownloadDialog(new Blob(data.splice(1).map((row) => (row[4] + "\n1. " + row[6] + "\n2. " + row[7] + "\n3. " + row[8] + "\n4. " + row[9] + "\nRationale: " + row[5] + "\n\n")), {type: "text/txt"}), fmt);
    }
  }

  displayDownloadDialog(file, fmt) {
    let element = document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(file));
    element.setAttribute('download', 'kameleon_' + this.state.title + '_' + this.state.hesiname + '_' + this.state.vid + '.' + fmt);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    this.markDownloaded(fmt.toLowerCase());
  }

  downloadJson() {
    let stateCopy = {allRules: this.state.allRules, title: this.state.title, selected: this.state.selected, hesiname: this.state.hesiname, vid: this.state.vid};
    let file = new Blob([JSON.stringify(stateCopy)], {type: "application/json"});
    let element = document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(file));
    element.setAttribute('download', 'kameleon_' + this.state.title + '_' + this.state.hesiname + '_' + this.state.vid + '.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    this.markDownloaded('json')
  }

  markDownloaded(type) {
    fetch(`//${facadeService}/downloaded`, {
      method: 'POST',
      body: JSON.stringify({
        problem_id: parseInt(this.props.ruleIndex),
        type:       type,
      }),
    })
      .then(resp => {
        this.setState({ lastDownload: new Date().toDateString() });
        return resp.text();
      })
      .then(text => console.log(`donwloaded resp: ${text}`))
      .catch(console.error);
  }

  uploadJson(e) {
    var file = e.target.files[0];
    if (!file) return;
    var reader = new FileReader();
    reader.onload = (ee) => {
      var contents = ee.target.result;
      var json_contents = JSON.parse(contents);
      this.grammarUpdate(json_contents.allRules);
    };
    reader.readAsText(file);
  }

  grammarUpdate(newRules) {
    this.setState({
      allRules: newRules
    });
    this.debouncedSave();
    this.runWorker(newRules, this.state.selected, this.state.optionCount);
  }

  setOptionCount(optionCount) {
    this.setState({optionCount: optionCount});
    this.runWorker(this.state.allRules, this.state.selected, optionCount);
  }

  runWorker(newRules, selection, optionCount) {
    let grammar = this.parseRulesParam(newRules);
    if (this.worker !== null) {
      this.worker.terminate();
    }

    this.worker = new window.Worker('/grammarworker.js');
    this.worker.onerror = (err) => err;
    this.worker.onmessage = (e) => {
      this.setState({
        workerOutput: e.data,
        workerUpdating: false
      });
      this.worker.terminate();
    };
    this.worker.postMessage({
      grammar: grammar,
      selection: selection,
      optionCount: optionCount
    });
    this.setState({workerUpdating: true});
  }

  render() {
    //const rng = seedrandom(this.state.rngSeed);

    if (this.state.loading || this.state.allRules === undefined) {
      return (<>"LOADING"</>);
    }

    // TODO move these checks into grammarworker
    let symbolsDefined = this.symbolsDefined(this.parseGrammar());
    let symbolsUsed = this.symbolsUsed(this.parseGrammar());

    let errors = [];
    let warnings = [];

    if (symbolsDefined.indexOf("S") === -1) {
      errors.push({message: "Required symbol \"S\" is not defined."});
    }
    if (symbolsDefined.indexOf("CO") === -1) {
      errors.push({message: "Required symbol \"CO\" is not defined."});
    }
    if (symbolsDefined.indexOf("DO") === -1) {
      errors.push({message: "Required symbol \"DO\" is not defined."});
    }

    symbolsUsed.forEach((usage) => {
      if (symbolsDefined.indexOf(usage.token) < 0) {
        errors.push({message: "Undefined symbol \"" + usage.token + "\"", rules: [[usage.usedby, usage.index]]});
      }
    });

    if (this.hasCycles(this.parseGrammar(), "S")) {
      errors.push({message: "Grammar has cycles reachable from \"S\"."});
    }
    if (this.hasCycles(this.parseGrammar(), "CO")) {
      errors.push({message: "Grammar has cycles reachable from \"CO\"."});
    }
    if (this.hasCycles(this.parseGrammar(), "DO")) {
      errors.push({message: "Grammar has cycles reachable from \"DO\"."});
    }

    let repeatedSymbols = [];
    symbolsDefined.forEach((symb1, i) => {
        symbolsDefined.forEach((symb2, j) => {
            if (i <= j) return;
            if (symb1 === symb2 && repeatedSymbols.indexOf(symb1) < 0) {
              repeatedSymbols.push(symb1);
            }
        });
    });
    repeatedSymbols.forEach((symb) => {
      errors.push({message: "Symbol \"" + symb + "\" has multiple definitions.", rules: [[symb, -1]]});
    });

    // errors to test for
    // 1. grammar must be acyclic
    // 2. no duplicate definitions (X)
    // 3. grammar must define "S", "CO", and "DO" symbols (X)
    // 4. all used symbols must be defined (X)

    let allOutputs = [];
    let allCorrect = [], allDistractors = [];
    let sampleGen = [];
    let correct = [];
    let distract = [];
    let countGen = 0;
    let comboCount = 0;

    if (errors.length === 0 && this.state.workerOutput !== null) {
      comboCount = this.state.workerOutput.allcombos.length;
      allOutputs = this.state.workerOutput.allOutputs;
      allCorrect = this.state.workerOutput.allCorrect;
      allDistractors = this.state.workerOutput.allDistractors;

      if (this.state.generateAll) {
        if (this.state.generateFull) {
          // move allcombos into sampleGen
          for (let choice of this.state.workerOutput.allcombos) {
            sampleGen.push(choice.stem);
            correct.push(choice.match.correct);
            distract.push(choice.match.distractors);
          }
        }
      } else {
        // if items are selected, generate new items to be as different as possible from the selected items
        let chosen = this.state.workerOutput.chosen;

        for (let choice of chosen) {
          sampleGen.push(allOutputs[choice.stem]);
          correct.push(allCorrect[choice.correct]);
          distract.push(choice.distract.map(didx => allDistractors[didx]));
        }

      }
      countGen = this.count(this.parseGrammar(), "S");
    }

    return (<>
      <div id="titleBar">
        {!!this.state.category && <span className="category">{this.state.category} as of {this.state.lastUpdate}</span>}
        <span className="title">{this.state.title}</span>
        <button onClick={() => this.setState({showHelp: true})}>Help</button>
        <button onClick={(e) => document.getElementById("uploadButton").click()}>Upload JSON</button>
        <input id="uploadButton" type="file" style={{display: 'none'}} onChange={(e) => this.uploadJson(e)} />
        <div className="dropdown">
          <button className="dropbtn">Download ▼</button>
          <div className="dropdown-content">
            { !this.state.lastDownload && <div className='last-download'>Not yet downloaded</div>}
            { this.state.lastDownload && <div className='last-download'>Last download: {this.state.lastDownload}</div>}
            <button onClick={() => this.download("CSV", false)}>CSV</button>
            <button onClick={() => this.download("CSV", true)}>CSV (Only selected)</button>
            <button onClick={() => this.download("TXT", false)}>TXT</button>
            <button onClick={() => this.download("TXT", true)}>TXT (Only selected)</button>
            <button onClick={() => this.downloadJson()}>JSON</button>
            <button onClick={() => this.download("XLS", false)}>XLS</button>
            <button onClick={() => this.download("DOCX", false)}>DOCX</button>
          </div>
        </div>
        <button onClick={() => this.save()}>Save</button>
        <button style={{display: this.state.selected.length > 0 ? 'inline' : 'none'}} onClick={() => this.clearSelected()}>Clear Selection</button>
        <button><Link to={`/`}>Index</Link></button>
        <span style={{float: "right"}}>{this.state.workerUpdating ? "loading" : ""}</span>
      </div>

      <div id="grammarEditor">
        {this.state.allRules.map(
          (e, i) => <div key={i} className="ruleRow">
            <button className="delete" onClick={() => this.deleteRule(i)} disabled={i === 0}>✗</button>
            <Rule name={e[0]} value={e[1]} setName={this.setName(i)} setVal={this.setVal(i)} />
          </div>
        )}
        <button onClick={() => this.addRule()}>Add rule</button>
        <button onClick={() => this.save()}>Save</button>
      </div>

      <div id="outputPanel">
        <button onClick={() => this.toggleAll()}>
          Show {this.state.generateAll ? "random" : "all"} outputs
        </button>
        <button onClick={() => this.toggleFull()}
                style={{display: this.state.generateAll ? 'inline' : 'none'}}>
          Show with{this.state.generateFull ? "out" : ""} answers
        </button>
        <button onClick={() => this.rerandom()}
                style={{display: this.state.generateAll ? 'none' : 'inline'}}>
          Generate new outputs
        </button>
        <button onClick={() => this.setOptionCount(this.state.optionCount - 1)}
                style={{display: this.state.generateAll ? 'none' : 'inline'}}>
          Fewer outputs
        </button>
        <button onClick={() => this.setOptionCount(this.state.optionCount + 1)}
                style={{display: this.state.generateAll ? 'none' : 'inline'}}>
          More outputs
        </button>

        <br/>

        <div id="errors">
          {errors.map((error, i) => (
            <div key={i} onMouseOver={() => this.highlight(error.rules)}
                 onMouseOut={() => this.dehighlight(error.rules)}>
              {error.message}
            </div>
          ))}
        </div>

        <div id="warnings">
          {warnings.map((warning, i) => (
            <div key={i} onMouseOver={() => this.highlight(warning.rules)}
                 onMouseOut={() => this.dehighlight(warning.rules)}>
              {warning.message}
            </div>
          ))}
        </div>

        <div className="count">
          {countGen} possible output{countGen === 1 ? "" : "s"}<br/>
          {comboCount > 0 ? `Generated ${comboCount} combination${comboCount === 1 ? "" : "s"}.` : "" }<br/>
          {this.state.selected.length} selected<br/>
          {this.state.selected.length + this.state.optionCount} total choice{(this.state.selected.length + this.state.optionCount === 1) ? "" : "s"} generated<br/>
        </div>

        <br/>

        <div>
          {sampleGen.map((sample, i) => (
            <div key={sample + i.toString()}
                 className={`sample ${this.findSelected(sample.index, correct[i].index, distract[i].map(dis => dis.index)) >= 0 ? 'selected' : ''}`}
                 onDoubleClick={() => this.toggleSelected(sample.index, correct[i].index, distract[i].map(dis => dis.index))}>
              <div onMouseOver={() => this.highlight(sample.rules)}
                   onMouseOut={() => this.dehighlight(sample.rules)}>
                {sample.lemma}
              </div>

              <ol>
                {correct[i] ?
                  <li key={sample + i.toString() + ".c"}
                      onMouseOver={() => this.highlight(correct[i].rules)}
                      onMouseOut={() => this.dehighlight(correct[i].rules)}>
                    {correct[i].lemma}
                  </li> : <li className="error">NO MATCHING ANSWER FOUND</li>
                }
                {distract[i].map((distractor, j) =>
                  <li key={sample + i.toString() + ".d" + j.toString()}
                      onMouseOver={() => this.highlight(distractor.rules)}
                      onMouseOut={() => this.dehighlight(distractor.rules)}>
                    {distractor.lemma}
                  </li>
                )}
              </ol>

              Rationale: {correct[i].rationale}
            </div>
          ))}
        </div>

        <div style={{display: this.state.generateAll && !this.state.generateFull ? 'block' : 'none'}}>
          <ol className="allOutputs">
            {allOutputs.map((sample, i) => (
              <li key={i.toString()}
                  onMouseOver={() => this.highlight(sample.rules)}
                  onMouseOut={() => this.dehighlight(sample.rules)}>
                {sample.lemma} [Answer tag: {sample.tags || "none"}, Exclusive tag: {sample.oneOf.join("@") || "none"}]
              </li>
            ))}
          </ol>

          Correct Answers
          <ol>
            {allCorrect.map((sample, i) => (
              <li key={i.toString()}
                  onMouseOver={() => this.highlight(sample.rules)}
                  onMouseOut={() => this.dehighlight(sample.rules)}>
                {sample.lemma} [Answer tag: {sample.tags || "none"}, Exclusive tag: {sample.oneOf.join("@") || "none"}]
              </li>
            ))}
          </ol>

          Distractors
          <ol>
            {allDistractors.map((sample, i) => (
              <li key={i.toString()}
                  onMouseOver={() => this.highlight(sample.rules)}
                  onMouseOut={() => this.dehighlight(sample.rules)}>
                {sample.lemma} [Answer tag: {sample.tags || "none"}, Exclusive tag: {sample.oneOf.join("@") || "none"}]
              </li>
            ))}
          </ol>
        </div>
      </div>

      <div id="overlay" style={{display: this.state.showHelp ? 'block' : 'none'}}
           onClick={() => this.setState({showHelp: false})}>
        <div id="text">
          Help!

          <ul>
            <li key="1">
              The required rules are:
              <ul>
                <li key="a">S: stems</li>
                <li key="b">CO: correct options</li>
                <li key="c">DO: distractor options</li>
              </ul>
            </li>
            <li key="2">
              One rule can refer to another using arbitrary symbol names;
              use them in the rules using angled brackets (like &lt;tags&gt;).
            </li>
            <li key="3">
              Correct options and distractors will be matched up with the
              stem with tags, if they are present. Tags are delimited using
              # marks (e.g., #blood#) and can appear anywhere in a grammar.
              If one or more tags are present in a resulting stem, only
              correct options and distractors that contain exactly the
              same tags will match.
            </li>
            <li key="4">
              Likewise, exclusion tags may be present. Exclusion tags will
              eliminate any correct options or
              distractors that contain the same tag. These are set off with
              the @ symbol (e.g., @blood@) and can appear anywhere in a
              grammar.
            </li>
            <li key="5">
              Rationales may be added to correct answers between curly braces ({'"{"'} and {'"}"'}).
            </li>
            <li key="6">
              Double-clicking an item (stem and answers) will select it.
              In the random views screen, the remaining items will be chosen
              to be as dissimilar to the selected items as possible.
            </li>
            <li key="7">
              The system does not handle cyclic grammars. If there is a
              cycle (say if a rule refers to itself), the grammar simply
              will not render any output.
            </li>
          </ul>
          Click anywhere to hide this screen.
        </div>
      </div>
    </>);
  }
}

class Index extends React.Component {
  state = {
    problems: null,
    edit: [[], [], [], [], []],
    showProblems: null,
  }

  componentDidMount() {
    fetch(`//${facadeService}/problems`)
    .then(res => res.json())
    .then(problems => {
      if (this.props.author) {
        problems = problems.filter(problem => problem.author === this.props.author);
      }
      problems = problems.map(p => {
        if (p.lastUpdate) {
          p.lastUpdate = new Date(p.lastUpdate);
        }
        return p
      });
      this.setState({ problems: problems })
    })
    .catch(e => console.error("fetching problems: %O", e));
  }

  edit(problemId, key) {
    console.log('edit:', problemId, key);
    let newEdit = this.state.edit.slice();
    newEdit[key][problemId] = true;
    this.setState({edit: newEdit});
  }

  doneEditing(problemId, key) {
    console.log('done editing:', problemId, key);
    let newEdit = this.state.edit.slice();
    newEdit[key][problemId] = null;
    this.setState({edit: newEdit});
    let idx = this.state.problems.map((problem, idx) => [problem.id, idx]).filter(problem => problem[0] === problemId)[0][1];
    this.save(idx);
  }

  updateTitle(problemId, newTitle) {
    let newProblems = this.state.problems.slice();
    let idx = this.state.problems.map((problem, idx) => [problem.id, idx]).filter(problem => problem[0] === problemId)[0][1];
    newProblems[idx].title = newTitle;
    this.setState({problems: newProblems});
  }

  updateAuthor(problemId, newAuthor) {
    let newProblems = this.state.problems.slice();
    let idx = this.state.problems.map((problem, idx) => [problem.id, idx]).filter(problem => problem[0] === problemId)[0][1];
    newProblems[idx].author = newAuthor;
    this.setState({problems: newProblems});
  }

  updateCategory(problemId, newCategory) {
    let newProblems = this.state.problems.slice();
    let idx = this.state.problems.map((problem, idx) => [problem.id, idx]).filter(problem => problem[0] === problemId)[0][1];
    if (newProblems[idx].category !== newCategory) {
      newProblems[idx].category = newCategory;
      newProblems[idx].lastUpdate = new Date();
      this.setState({problems: newProblems});
    }
  }

  updateRationale(problemId, newRationale) {
    let newProblems = this.state.problems.slice();
    let idx = this.state.problems.map((problem, idx) => [problem.id, idx]).filter(problem => problem[0] === problemId)[0][1];
    newProblems[idx].rationale = newRationale;
    this.setState({problems: newProblems});
  }

  updateHesiname(problemId, newHesiname) {
    let newProblems = this.state.problems.slice();
    let idx = this.state.problems.map((problem, idx) => [problem.id, idx]).filter(problem => problem[0] === problemId)[0][1];
    newProblems[idx].hesiname = newHesiname;
    this.setState({problems: newProblems});
  }

  create() {
    fetch(`//${facadeService}/meta/`, {
      method: "POST"
    })
      .then(res => res.json())
      .then(jres => {
        let newProblems = this.state.problems.slice();
        newProblems.push({id: jres, title: "untitled", author: "", rationale: "", hesiname: ""});
        this.setState({problems: newProblems});
      })
      .catch(console.error);
  }

  delete(problemId) {
    fetch(`//${facadeService}/delete/` + problemId, { method: "POST" })
      .then(res => {
        if (res.status === 200) {
          let newProblems = this.state.problems.filter(problem => problem.id !== problemId);
          this.setState({problems: newProblems});
        }
      })
      .catch(console.error);
  }


  save(idx) {
    fetch(`//${facadeService}/meta/`+this.state.problems[idx].id, {
      method: "POST",
      body: JSON.stringify(this.state.problems[idx])
    })
      .then(res => {
        if (res.status !== 200) {
          console.log(`bad status: ${res.status}`);
        }
      })
      .catch(console.error);
  }

  changeSearch(e) {
    if (e.target.value.length === 0) {
      this.setState({showProblems: null});
      return;
    }

    fetch(`//${facadeService}/search`, { method: 'POST', body: JSON.stringify({query:e.target.value}) })
      .then(res => {
        if (res.status === 200) {
          return res.json()
        }
        console.log(`bad status: ${res.status}`);
      })
      .then(json => this.setState({showProblems: json.ids}))
      .catch(console.error);
  }

  render() {
    const { problems, showProblems } = this.state;
    return (
      <div>
        <div className="searchPane">
          <div className="search-container">
          <input type="search"
                 placeholder="Search"
                 onChange={(e) => this.changeSearch(e)}
          />
          </div>
        </div>
      <div className="pmeta-container">
        <table className="pmeta">
          <tbody>
          { problems ? (
            problems.map(problem => (
              (showProblems === null || showProblems.includes(problem.id)) && (
              <tr key={problem.id} style={{display: problem.deleted_at ? "none" : "table-row"}}>
              {this.state.edit[0][problem.id] ?
               (<td><input type="text" autoFocus value={problem.title} onChange={(event) => this.updateTitle(problem.id, event.target.value)} onKeyDown={(event) => { if (event.key === 'Enter' || event.key === 'Escape') { this.doneEditing(problem.id, 0); event.preventDefault(); event.stopPropagation(); }}} onBlur={(event) => this.doneEditing(problem.id, 0)} /></td>) : (<td onDoubleClick={() => this.edit(problem.id, 0)}>{problem.title || '[untitled]'}</td>)}
              {this.state.edit[1][problem.id] ?
               (<td><input type="text" autoFocus value={problem.author} onChange={(event) => this.updateAuthor(problem.id, event.target.value)} onKeyDown={(event) => { if (event.key === 'Enter' || event.key === 'Escape') { this.doneEditing(problem.id, 1); event.preventDefault(); event.stopPropagation(); }}} onBlur={(event) => this.doneEditing(problem.id, 1)} /></td>) : (<td onDoubleClick={() => this.edit(problem.id, 1)}>{problem.author || '[no author]'}</td>)}
              {this.state.edit[2][problem.id] ? (
               <td>
                 <select defaultValue={problem.category} name="categories" id="category-select"
                         onChange={e => this.updateCategory(problem.id, e.target.value)}
                         onKeyDown={(event) => {
                           if (event.key === 'Enter' || event.key === 'Escape') {
                             this.doneEditing(problem.id, 2);
                             event.preventDefault();
                             event.stopPropagation();
                           }
                         }}
                         onBlur={(event) => {
                           console.log('blur', problem.id, '2');
                           this.doneEditing(problem.id, 2)}}>
                   <option key="" value=""></option>
                   {validCategories.map(cat => (<option key={cat} value={cat}>{cat}</option>))}
                 </select>
               </td>
              ) : (
               <td onDoubleClick={() => this.edit(problem.id, 2)}>
                {problem.category ? problem.category + ` as of ${problem.lastUpdate.toDateString()}` : '[new]'}</td>
              )}
              {this.state.edit[3][problem.id] ?
               (<td><TextareaAutosize autoFocus value={problem.rationale} onChange={(event) => this.updateRationale(problem.id, event.target.value)} onKeyDown={(event) => { if (event.key === 'Enter' || event.key === 'Escape') { this.doneEditing(problem.id, 3); event.preventDefault(); event.stopPropagation(); }}} onBlur={(event) => this.doneEditing(problem.id, 3)} /></td>) : (<td onDoubleClick={() => this.edit(problem.id, 3)}>{problem.rationale || '[no default rationale]'}</td>)}
              {this.state.edit[4][problem.id] ?
               (<td><TextareaAutosize autoFocus value={problem.hesiname} onChange={(event) => this.updateHesiname(problem.id, event.target.value)} onKeyDown={(event) => { if (event.key === 'Enter' || event.key === 'Escape') { this.doneEditing(problem.id, 4); event.preventDefault(); event.stopPropagation(); }}} onBlur={(event) => this.doneEditing(problem.id, 4)} /></td>) : (<td onDoubleClick={() => this.edit(problem.id, 4)}>{problem.hesiname || '[no hesi name]'}</td>)}
              <td align="right">[<Link to={`/edit/${problem.id}`}>edit</Link> | <button className="linklike" onClick={() => window.confirm("Want to delete?") && this.delete(problem.id)}>delete</button>]</td>
              </tr>
            )))
          ) : (
            <tr><td>Loading ... </td></tr>
          )}
      </tbody>
        </table>
      </div>
      <div className="button-container">
        <button onClick={() => this.create() }>Create</button>
      </div>
      </div>
    );
  }
}


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact={true} path="/" component={Index} />
      <Route path="/author/:author" render={({ match }) =>
        <Index author={match.params.author} />
      } />
      <Route path="/edit/:grammarId" render={({ match }) =>
        <GrammarEditor ruleIndex={match.params.grammarId} />
      }/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
